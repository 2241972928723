import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { bg } from 'vuetify/locale';
import 'vuetify/styles';

import { BLUE_THEME } from '@/theme/LightTheme';

import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';

export default createVuetify({
    components: {
        ...components,
        VDateInput,
    },
    directives,
    theme: {
        defaultTheme: 'BLUE_THEME',
        themes: {
            BLUE_THEME,
        },
    },
    defaults: {
        VBtn: {},
        VCard: {
            rounded: 'md',
        },
        VTextField: {
            rounded: 'lg',
        },
        VTooltip: {
            location: 'top',
        },
        VDateInput: {
            locale: 'bg-BG',
        },
        VDatePicker: {
            locale: 'bg',
            firstDayOfWeek: 1,
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    locale: {
        locale: 'bg', // Настройваме текущия език на български
        messages: {
            bg,
        },
    },
});
