import { computed, reactive, ref, watch } from 'vue';

import agent from '@/api/agent';
import type { Article, ArticleData, ArticleForm } from '@/models/article';
import type { CategoryModel } from '@/models/category';
import Pagination, { PagingParams } from '@/models/pagination';

import { useCategoryStore } from './categoryStore';
import { formatDateForInput } from './dateUtils';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';

interface UserArticlesState {
  userArticles: Article[] | null;
}
interface CustomFile extends File {
  fileTitle: string;
}
interface DecodedToken {
  name: string;
  id: string;
}

export const useArticleStore = defineStore('articles', () => {
  const categoryStore = useCategoryStore();
  const state = ref<UserArticlesState>({ userArticles: null });
  const parentCategories = ref<CategoryModel[]>([]);
  const articles = reactive(new Map<string, ArticleData>());
  const article = ref();

  const predicate = ref(new Map().set('all', true));

  const pagination = ref({} as Pagination);
  const pagingParams = ref<PagingParams>(new PagingParams());

  const axiosParams = computed(() => {
    const params = new URLSearchParams();
    params.append('pageNumber', pagingParams.value.pageNumber.toString());
    params.append('pageSize', pagingParams.value.pageSize.toString());
    predicate.value.forEach((value, key) => {
      params.append(key, value.toString());
    });
    return params;
  });

  function setPagingParams(pagingPar: PagingParams) {
    return (pagingParams.value = pagingPar);
  }

  function setPagination(pagi: Pagination) {
    return (pagination.value = pagi);
  }

  function setPredicate(predicateKey: string, value: string | number | number[] | null) {
    const resetPredicate = () => {
      predicate.value.forEach((_, key) => {
        predicate.value.delete(key);
      });
    };

    switch (predicateKey) {
      case 'all':
        resetPredicate();
        predicate.value.set('all', true);
        break;
      case 'isDeleted':
        if (value === null) {
          predicate.value.delete('isDeleted');
          predicate.value.delete('isDraft');
          break;
        }
        predicate.value.delete('all');
        if (value === 'Публикувани страници') {
          predicate.value.delete('isDeleted');
          predicate.value.delete('isDraft');
          predicate.value.set('isDeleted', false);
        } else if (value === 'Архивирани страници') {
          predicate.value.delete('isDraft');
          predicate.value.delete('isDeleted');
          predicate.value.set('isDeleted', true);
        } else {
          predicate.value.set('isDeleted', false);
          predicate.value.set('isDraft', false);
        }
        break;
      case 'isDraft':
        if (value === null) {
          predicate.value.delete('isDeleted');
          predicate.value.delete('isDraft');
          break;
        }
        predicate.value.delete('all');

        predicate.value.delete('isDeleted');
        predicate.value.delete('isDraft');
        predicate.value.set('isDraft', true);
        break;
      case 'authorId':
        if (value === null) {
          predicate.value.delete('authorId');
          break;
        }
        predicate.value.delete('all');
        predicate.value.delete('authorId');
        predicate.value.set('authorId', value as number);
        break;
      case 'search':
        if (value === null) {
          predicate.value.delete('search');
          break;
        }
        predicate.value.delete('all');
        predicate.value.delete('search');
        predicate.value.set('search', value);
        break;
      case 'categoriesId':
        if (Array.isArray(value) && value.length === 0) {
          predicate.value.delete('categoriesId');
          break;
        }
        predicate.value.delete('all');
        predicate.value.delete('categoriesId');
        predicate.value.set('categoriesId', value);
        break;
    }
  }

  const articlesArray = computed((): ArticleData[] => {
    return Array.from(articles.values());
  });

  function getLoggedInUserName(params: string): string {
    const token = Cookies.get('token');
    if (token) {
      const decodedToken = jwtDecode<DecodedToken>(token);
      if (params === 'name') {
        return decodedToken.name;
      }
      return decodedToken.id;
    }
    return '';
  }

  const formValues = ref<ArticleForm>({
    title: '',
    authorId: '',
    categoryId: null,
    parentCategoryId: null,
    url: '',
    content: {},
    featuredImage: null,
    metaTitle: '',
    metaDescription: '',
    metaRobots: {
      noIndex: false,
      noFollow: false,
    },
    ogTitle: '',
    ogDescription: '',
    ogImage: null,
    datePublished: null,
    endDate: null,
    files: [],
    isDraft: false,
    isDeleted: false,
    attachedFiles: [],
  });

  const form = ref({
    title: '',
    authorId: getLoggedInUserName('name'),
    categoryId: null,
    parentCategoryId: null,
    url: '',
    content: {},
    featuredImage: null as File | null,
    featuredImageAsFile: null as File | null,
    featuredImagePreview: null as string | null,
    metaTitle: '',
    metaDescription: '',
    metaRobots: {
      noIndex: false,
      noFollow: false,
    },
    ogTitle: '',
    ogDescription: '',
    ogImage: null as File | null,
    ogImageAsFile: null as File | null,
    ogImagePreview: null as string | null,
    datePublished: '',
    endDate: '',
    galleryImages: [] as File[] | null,
    galleryImagesAsFile: [] as File[],
    isDraft: false,
    isDeleted: false,
    attachedFiles: [] as File[],
  });

  function updateFormValues() {
    formValues.value = {
      title: form.value.title,
      authorId: getLoggedInUserName('id'),
      categoryId: form.value.categoryId,
      parentCategoryId: form.value.parentCategoryId,
      url: form.value.url,
      content: form.value.content,
      featuredImage: form.value.featuredImageAsFile,
      metaTitle: form.value.metaTitle,
      metaDescription: form.value.metaDescription,
      metaRobots: form.value.metaRobots,
      ogTitle: form.value.ogTitle,
      ogDescription: form.value.ogDescription,
      ogImage: form.value.ogImageAsFile,
      datePublished: formatDateForInput(form.value.datePublished),
      endDate: formatDateForInput(form.value.endDate),
      files: form.value.galleryImagesAsFile,
      isDraft: form.value.isDraft,
      isDeleted: form.value.isDeleted,
      attachedFiles: form.value.attachedFiles,
    };
  }

  function onImageChange(
    imageType: 'featuredImageAsFile' | 'ogImageAsFile' | 'galleryImagesAsFile',
    file: File | File[] | null,
  ) {
    if (file) {
      if (Array.isArray(file) && imageType === 'galleryImagesAsFile') {
        const currentFiles = form.value.galleryImagesAsFile;
        const hasChanges =
          !currentFiles ||
          currentFiles.length !== file.length ||
          file.some((f, i) => f !== currentFiles[i]);

        if (hasChanges) {
          form.value.galleryImagesAsFile = file;
        }
      } else if (!Array.isArray(file)) {
        if (imageType === 'featuredImageAsFile') {
          if (form.value.featuredImagePreview) {
            URL.revokeObjectURL(form.value.featuredImagePreview);
          }
          form.value.featuredImagePreview = URL.createObjectURL(file);
          form.value.featuredImageAsFile = file;
          form.value.featuredImage = file;
        } else if (imageType === 'ogImageAsFile') {
          if (form.value.ogImagePreview) {
            URL.revokeObjectURL(form.value.ogImagePreview);
          }
          form.value.ogImagePreview = URL.createObjectURL(file);
          form.value.ogImageAsFile = file;
          form.value.ogImage = file;
        }
      }
    } else {
      if (imageType === 'featuredImageAsFile') {
        if (form.value.featuredImagePreview) {
          URL.revokeObjectURL(form.value.featuredImagePreview);
        }
        form.value.featuredImagePreview = null;
        form.value.featuredImageAsFile = null;
        form.value.featuredImage = null;
      } else if (imageType === 'ogImageAsFile') {
        if (form.value.ogImagePreview) {
          URL.revokeObjectURL(form.value.ogImagePreview);
        }
        form.value.ogImagePreview = null;
        form.value.ogImageAsFile = null;
        form.value.ogImage = null;
      } else if (imageType === 'galleryImagesAsFile') {
        form.value.galleryImagesAsFile = [];
        form.value.galleryImages = [];
      }
    }
  }

  const bulgarianToLatin: { [key: string]: string } = {
    А: 'A',
    Б: 'B',
    В: 'V',
    Г: 'G',
    Д: 'D',
    Е: 'E',
    Ж: 'ZH',
    З: 'Z',
    И: 'I',
    Й: 'Y',
    К: 'K',
    Л: 'L',
    М: 'M',
    Н: 'N',
    О: 'O',
    П: 'P',
    Р: 'R',
    С: 'S',
    Т: 'T',
    У: 'U',
    Ф: 'F',
    Х: 'H',
    Ц: 'TS',
    Ч: 'CH',
    Ш: 'SH',
    Щ: 'SHT',
    Ъ: 'A',
    Ь: 'Y',
    Ю: 'YU',
    Я: 'YA',
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'sht',
    ъ: 'a',
    ь: 'y',
    ю: 'yu',
    я: 'ya',
  };

  const transliterate = (text: string) => {
    const transliterated = [];
    const lowerText = text.toLowerCase();
    let totalLength = 0;
    let lastChar = '';

    for (let i = 0; i < lowerText.length; i++) {
      const char = lowerText[i];
      let transliteratedChar;

      if (bulgarianToLatin[char]) {
        transliteratedChar = bulgarianToLatin[char];
      } else if ((char >= 'a' && char <= 'z') || (char >= '0' && char <= '9')) {
        transliteratedChar = char;
      } else {
        transliteratedChar = '-';
      }

      if (transliteratedChar === '-' && lastChar === '-') {
        continue;
      }

      if (totalLength + transliteratedChar.length > 200) {
        break;
      }

      transliterated.push(transliteratedChar);
      totalLength += transliteratedChar.length;
      lastChar = transliteratedChar;
    }
    while (transliterated[0] === '-') {
      transliterated.shift();
    }
    while (transliterated[transliterated.length - 1] === '-') {
      transliterated.pop();
    }

    return transliterated.join('');
  };

  function urlChange(tit: any, datePublished?: any, isDraft?: boolean) {
    const currentDate = new Date();

    if (datePublished === undefined || new Date(datePublished) >= currentDate || isDraft) {
      if (tit) {
        form.value.url = transliterate(tit).toLocaleLowerCase().replace(/\s+/g, '-');
      } else {
        form.value.url = '';
      }
    }
  }

  function getAllParentCategories(categoryId: number, categoriesArray: CategoryModel[]): any[] {
    let currentCategory = categoriesArray.find(category => category.id === categoryId);
    while (currentCategory && currentCategory.parentId !== 0 && currentCategory !== undefined) {
      const parentCategory: CategoryModel | undefined = categoriesArray.find(
        category => category.id === currentCategory!.parentId,
      );

      if (parentCategory) {
        parentCategories.value.push(parentCategory);
        currentCategory = parentCategory;
      } else {
        break;
      }
    }

    return parentCategories.value;
  }

  function watchCategoriesChange(categories: any) {
    console.log(categories);

    watch(
      () => categories,
      newCategoryId => {
        console.log('newCategory', newCategoryId);

        parentCategories.value = [];
        if (newCategoryId) {
          const selectedCategory = categoryStore.groupedCategoriesArray.find(
            category => category.id === newCategoryId,
          );
          console.log('selectedCategory -> ', selectedCategory);

          if (selectedCategory) {
            parentCategories.value = getAllParentCategories(
              newCategoryId,
              categoryStore.groupedCategoriesArray,
            );
          } else {
            parentCategories.value = [];
          }
        } else {
          parentCategories.value = [];
          form.value.url = '';
          form.value.parentCategoryId = null;
          if (article.value !== undefined) {
            article.value.parentCategoryId = null;
          }
          // article.value.parentCategoryId = null;
        }
      },
      { immediate: true, deep: true },
    );
  }

  // function watchCategoriesChange(categories: any) {
  //   console.log('watchCategoriesChange called with:', categories);

  //   watch(
  //     () => categories,
  //     newCategories => {
  //       parentCategories.value = [];
  //       if (newCategories) {
  //         const selectedCategory = categoryStore.groupedCategoriesArray.find(
  //           category => category.id === newCategories,
  //         );

  //         if (selectedCategory) {
  //           parentCategories.value = getAllParentCategories(
  //             newCategories,
  //             categoryStore.groupedCategoriesArray,
  //           );
  //         }
  //       } else {
  //         parentCategories.value = [];
  //         form.value.url = '';
  //         form.value.parentCategoryId = null;
  //         if (article.value !== undefined) {
  //           article.value.parentCategoryId = null;
  //         }
  //       }
  //     },
  //     { immediate: true, deep: true },
  //   );
  // }

  function changeDate(date: string | null) {
    if (!date) {
      return 'Няма насрочена дата';
    }
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('bg-BG', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  async function getAllUserArticles() {
    // console.trace('getAllUserArticles called from:');
    try {
      if (predicate.value) {
        state.value.userArticles = null;
        articles.clear();
      }
      const userArticles = (await agent.Articles.getAllArticles(axiosParams.value)).data;

      if (!userArticles) {
        throw new Error('Грешка при зареждане на страниците');
      }
      setPagination(userArticles.pagination);

      for (const article of userArticles.data) {
        article.datePublished = changeDate(article.datePublished);
        article.endDate = changeDate(article.endDate);
        if (article.isDeleted) {
          article.status = 'Архивирана';
        } else if (article.isDraft) {
          article.status = 'Чернова';
        } else {
          article.status = 'Активна';
        }
        articles.set(article.id!, article);
      }
    } catch (error) {
      throw new Error('Грешка при зареждане на страниците');
    }
  }

  async function createArticle(preview = false) {
    try {
      updateFormValues();

      const formData = new FormData();
      for (const key in formValues.value) {
        if (formValues.value.hasOwnProperty(key)) {
          const value = (formValues.value as any)[key];

          if (value !== null && value !== undefined) {
            if (value instanceof Blob) {
              const fileName = (value as File).name || 'defaultName.jpg';
              let processedFileName = fileName;
              for (const ext of extensionsToRemove) {
                if (processedFileName.toLowerCase().endsWith(ext)) {
                  processedFileName = processedFileName.slice(0, -ext.length);
                  break;
                }
              }

              const file = new File([value], processedFileName, {
                type: value.type,
              });
              formData.append(key, file, processedFileName);
            } else if (Array.isArray(value) && value.every(item => item instanceof Blob)) {
              const processedFiles: CustomFile[] = [];

              value.forEach((blob, index) => {
                const fileName = (blob as File).name || `defaultName_${index}.jpg`;
                let processedFileName = fileName;
                for (const ext of extensionsToRemove) {
                  if (processedFileName.toLowerCase().endsWith(ext)) {
                    processedFileName = processedFileName.slice(0, -ext.length);
                    break;
                  }
                }
                const file = new File([blob], processedFileName, {
                  type: blob.type,
                }) as CustomFile;

                file.fileTitle = (blob as any).fileTitle || '';

                processedFiles.push(file);
              });
              processedFiles.forEach((file, index) => {
                formData.append(`${key}`, file, file.name);
                formData.append(
                  `${key}Title`,
                  JSON.stringify({ fileTitle: file.fileTitle, fileName: file.name }),
                );
              });
            } else {
              if (typeof value === 'object') {
                formData.append(key, JSON.stringify(value));
              } else {
                formData.append(key, value);
              }
            }
          }
        }
      }

      if (preview) {
        formData.append('preview', 'true');
      }

      // inspect formData
      // for (var pair of formData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]);
      // }
      const response = await agent.Articles.createArticle(formData);
      console.log('response -> ', response);

      state.value.userArticles?.push(response.data);
      resetForm();
    } catch (error: any) {
      if (error && error.status && error.status === 403)
        throw new Error('Нямате права за създаване на страница');
      throw new Error('Грешка при създаване на страницата');
    }
  }

  async function updateArticle(id: string) {
    try {
      updateFormValues();

      const formData = new FormData();
      for (const key in formValues.value) {
        if (formValues.value.hasOwnProperty(key)) {
          const value = (formValues.value as any)[key];
          if (value !== null && value !== undefined) {
            if (value instanceof Blob) {
              const fileName = (value as File).name || 'defaultName.jpg';
              let processedFileName = fileName;
              for (const ext of extensionsToRemove) {
                if (processedFileName.toLowerCase().endsWith(ext)) {
                  processedFileName = processedFileName.slice(0, -ext.length);
                  break;
                }
              }

              const file = new File([value], processedFileName, {
                type: value.type,
              });
              formData.append(key, file, processedFileName);
            } else if (Array.isArray(value) && value.every(item => item instanceof Blob)) {
              const processedFiles: CustomFile[] = [];

              value.forEach((blob, index) => {
                const fileName = (blob as File).name || `defaultName_${index}.jpg`;
                let processedFileName = fileName;
                for (const ext of extensionsToRemove) {
                  if (processedFileName.toLowerCase().endsWith(ext)) {
                    processedFileName = processedFileName.slice(0, -ext.length);
                    break;
                  }
                }

                const file = new File([blob], processedFileName, {
                  type: blob.type,
                }) as CustomFile;

                file.fileTitle = (blob as any).fileTitle || '';

                processedFiles.push(file);
              });
              processedFiles.forEach((file, index) => {
                formData.append(`${key}`, file, file.name);
                formData.append(
                  `${key}Title`,
                  JSON.stringify({ fileTitle: file.fileTitle, fileName: file.name }),
                );
              });
            } else {
              if (typeof value === 'object') {
                formData.append(key, JSON.stringify(value));
              } else {
                formData.append(key, value);
              }
            }
          }
        }
      }

      formData.append('id', id);
      const { data: response } = await agent.Articles.updateArticle(formData, id);

      if (articles.has(id)) {
        articles.set(id, response);
      }
      article.value = response;

      return response;
    } catch (error: any) {
      if (error && error.status && error.status === 403)
        throw new Error('Нямате права за обновяване на страницата');
      throw new Error('Грешка при обновяване на страницата');
    }
  }

  async function getArticle(id: string) {
    try {
      const hasArticle = articles.has(id);

      if (hasArticle) {
        article.value = articles.get(id);
        return;
      }
      const response = (await agent.Articles.getArticle(id)).data;
      if (!response) {
        throw new Error('Грешка при зареждане на страницата');
      }

      article.value = response;
    } catch (error) {
      console.error('error', error);
      throw new Error('Грешка при зареждане на страницата');
    }
  }

  async function deleteArticle(id: string) {
    try {
      const response = await agent.Articles.deleteArticle(id);
      if (response.status !== 200) {
        throw new Error('Грешка при архивиране на страницата');
      }
      if (articles.has(id)) {
        const article = articles.get(id);
        if (article) {
          const updatedArticle = { ...article, isDeleted: true, status: 'Архивирана' };
          articles.set(id, updatedArticle);
        }
      }
      toast.success('Страницата беше архивирана успешно');
    } catch (error) {
      throw new Error('Грешка при архивиране на страницата');
    }
  }

  const extensionsToRemove = [
    '.apng',
    '.png',
    '.avif',
    '.gif',
    '.jpg',
    '.jpeg',
    '.jfif',
    '.pjpeg',
    '.pjp',
    '.svg',
    '.webp',
  ];

  function resetForm() {
    Object.assign(form.value, {
      title: '',
      authorId: getLoggedInUserName('name'),
      categoryId: null,
      parentCategoryId: null,
      url: '',
      content: {},
      featuredImage: null as File | null,
      featuredImageAsFile: null as File | null,
      featuredImagePreview: null as string | null,
      metaTitle: '',
      metaDescription: '',
      metaRobots: {
        noIndex: false,
        noFollow: false,
      },
      ogTitle: '',
      ogDescription: '',
      ogImage: null as File | null,
      ogImageAsFile: null as File | null,
      ogImagePreview: null as string | null,
      datePublished: '',
      endDate: '',
      galleryImages: [] as File[] | null,
      galleryImagesAsFile: [] as File[],
      isDraft: false,
      isDeleted: false,
      attachedFiles: [] as File[],
    });
  }
  return {
    pagingParams,
    pagination,
    setPagingParams,
    setPagination,
    form,
    parentCategories,
    articles,
    watchCategoriesChange,
    urlChange,
    onImageChange,
    getAllUserArticles,
    createArticle,
    resetForm,
    getArticle,
    article,
    articlesArray,
    updateArticle,
    deleteArticle,
    setPredicate,
    changeDate,
  };
});
