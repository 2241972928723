export const config = {
  baseUrl: import.meta.env.VITE_COMMON_CLIENT_URL || 'http://localhost:3000/',
  imagesBasePath: 'common/images',
  fileBasePath: 'common/pdf',
};
const ALLOWED_MEDIA_FORMATS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.webp',
  '.svg',
  '.mp4',
  '.webm',
  '.mov',
  '.avi',
];
export function getImageUrl(filename: string): string {
  const baseServerUrl = config.baseUrl.replace(/\/api\/?$/, '');
  const extension = filename.substring(filename.lastIndexOf('.')).toLowerCase();

  const basePath = ALLOWED_MEDIA_FORMATS.includes(extension)
    ? config.imagesBasePath
    : config.fileBasePath;

  return `${baseServerUrl}/${basePath}/${filename}`;
}
