import ArticleCreate from '@/components/articles/dashboard/ArticleCreate.vue';
import ArticleUpdate from '@/components/articles/dashboard/ArticleUpdate.vue';
import BannerCreate from '@/components/banners/BannerCreate.vue';
import BannerEdit from '@/components/banners/BannerEdit.vue';

import Cookies from 'js-cookie';
// Importing Cookies to check authentication
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/auth/Error.vue'),
  },
  {
    path: '/',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/full/FullLayout.vue'),
    children: [
      {
        name: 'Начало',
        path: '/',
        component: () => import('@/views/dashboard/index.vue'),
      },
      {
        name: 'Потребители',
        path: '/users',
        component: () => import('@/views/components/users/Users.vue'),
      },
      {
        name: 'categories',
        path: '/categories',
        component: () => import('@/views/components/categories/Categories.vue'),
      },
      {
        name: 'banners',
        path: '/banners',
        component: () => import('@/views/components/banners/Banners.vue'),
      },
      {
        name: 'BannerCreate',
        path: '/banners/create',
        component: BannerCreate,
      },
      {
        name: 'BannerEdit',
        path: '/banners/edit/:id',
        component: BannerEdit,
      },
      {
        name: 'articles',
        path: '/articles',
        component: () => import('@/views/articles/Articles.vue'),
      },
      {
        path: 'articles/create',
        name: 'ArticleCreate',
        component: ArticleCreate,
      },
      {
        path: 'articles/edit/:id',
        name: 'ArticleUpdate',
        component: ArticleUpdate,
      },
      {
        name: 'Icons',
        path: '/icons',
        component: () => import('@/views/pages/Icons.vue'),
      },
      {
        name: 'Starter',
        path: '/sample-page',
        component: () => import('@/views/pages/SamplePage.vue'),
      },
      {
        name: 'Departments',
        path: '/department',
        component: () =>
          import('@/views/components/departments/Departments.vue'),
      },
      {
        name: 'Employees',
        path: '/employees',
        component: () => import('@/views/components/employees/Employees.vue'),
      },
      {
        name: 'UserProfile',
        path: '/profile',
        component: () => import('@/components/users/profile/UserProfile.vue'),
      },
      {
        name: 'Events',
        path: '/events',
        component: () => import('@/views/components/events/Events.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/layouts/blank/BlankLayout.vue'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        name: 'Login',
        path: '/login',
        component: () => import('@/views/pages/Login.vue'),
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = Cookies.get('token');

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !token) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;
