<template>
  <h1>Редактиране на страница</h1>
  <v-divider class="mt-4"></v-divider>

  <v-form @submit.prevent="submitForm" class="mt-8" v-if="articleStore.article">
    <v-text-field
      v-model="title"
      label="Име на страницата *"
      :error-messages="titleError"
      required
      hint="Редактирайте заглавие на страницата"
    >
    </v-text-field>

    <v-text-field v-model="articleStore.form.authorId" readonly label="Автор" type="input">
    </v-text-field>

    <div class="d-flex justify-space-around ga-10">
      <v-select
        v-model="categories"
        :items="categoryStore.groupedCategoriesArray"
        item-title="bgname"
        item-value="id"
        label="Категория *"
        :error-messages="categoriesError"
        required
        clearable
      >
      </v-select>

      <v-select
        v-model="articleStore.form.parentCategoryId"
        :items="articleStore.parentCategories"
        item-title="bgname"
        item-value="id"
        label="Главна категория"
        clearable
      >
      </v-select>
    </div>

    <v-text-field v-model="articleStore.form.url" readonly label="URL"> </v-text-field>

    <v-checkbox v-model="isArticle" label="Качване на съдържание"> </v-checkbox>

    <editor-component
      v-if="isArticle"
      ref="editorComponent"
      v-model:content="content"
      :error-messages="contentError"
      :initial-content="parsedEditorContent"
    >
    </editor-component>

    <div class="featured-image-section">
      <v-file-input
        v-model="featuredImageAsFile"
        label="Представено изображение"
        accept="image/*"
        @change="onFeaturedImageChange"
        @click:clear="onClearFeaturedImage"
        clearable
        :hint="
          articleStore.article.featuredImage
            ? 'Текущо изображение ще бъде заменено при качване на ново'
            : ''
        "
      >
      </v-file-input>

      <v-img
        v-if="articleStore.form.featuredImagePreview"
        :key="articleStore.form.featuredImagePreview"
        :src="articleStore.form.featuredImagePreview"
        class="mt-4 mb-4 w-25"
        @error="handleImageError"
      >
      </v-img>
    </div>

    <div class="mb-9">
      <h3 class="mb-5">Добавете галерия със снимки</h3>
      <ImageUploader v-model="galleryImagesAsFile" @galleryChange="handleGalleryChange" />
    </div>

    <div class="mb-9">
      <h3 class="mb-5">Добавете файлове</h3>
      <FileUploader v-model="attachedFiles" @fileChange="handleFileChange" />
    </div>
    <v-text-field v-model="articleStore.form.metaTitle" label="Meta Заглавие"> </v-text-field>

    <v-textarea v-model="articleStore.form.metaDescription" label="Meta Описание"> </v-textarea>

    <div class="d-flex ga-5">
      <v-checkbox v-model="articleStore.form.metaRobots.noIndex" label="No index"> </v-checkbox>
      <v-checkbox v-model="articleStore.form.metaRobots.noFollow" label="No follow"> </v-checkbox>
    </div>

    <v-text-field v-model="articleStore.form.ogTitle" label="Og: Заглавие"> </v-text-field>

    <div class="og-image-section">
      <v-file-input
        v-model="articleStore.form.ogImageAsFile"
        accept="image/*"
        label="Og: Изображение"
        :error-messages="ogImageError"
        @change="onOgImageChange"
        @click:clear="onClearOgImage"
        clearable
        :hint="
          articleStore.article.ogImage
            ? 'Текущо OG изображение ще бъде заменено при качване на ново'
            : ''
        "
      >
      </v-file-input>
      <v-img
        v-if="articleStore.form.ogImagePreview"
        :src="articleStore.form.ogImagePreview"
        max-width="400"
        max-height="200"
        class="mt-4 mb-4"
      >
      </v-img>
    </div>

    <v-textarea v-model="articleStore.form.ogDescription" label="Og: Описание"> </v-textarea>

    <div class="d-flex ga-3">
      <v-text-field
        v-model="articleStore.form.datePublished"
        label="Дата на публикуване"
        type="date"
        required
        :value="formatDateForInput(articleStore.form.datePublished)"
      >
      </v-text-field>

      <v-text-field
        v-model="articleStore.form.endDate"
        label="Насрочена дата на скриване"
        type="date"
        required
        :value="formatDateForInput(articleStore.form.endDate)"
      >
      </v-text-field>
    </div>

    <div class="d-flex ga-3">
      <v-btn type="submit" color="primary" :loading="loading">Запази промените</v-btn>

      <v-btn color="warning" @click="saveDraft">Запази като чернова</v-btn>
      <v-btn color="error" :disabled="loading" @click="cancelEdit">Отказ</v-btn>
    </div>
  </v-form>

  <v-progress-circular v-else indeterminate color="primary"> </v-progress-circular>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { getImageUrl } from '@/api/config';
import EditorComponent from '@/components/EditorComponent.vue';
import CustomFile from '@/models/file';
import { useArticleStore } from '@/stores/articlesStore';
import { useCategoryStore } from '@/stores/categoryStore';
import { formatDateForInput } from '@/stores/dateUtils';

import FileUploader from './FileUploader.vue';
import ImageUploader from './ImageUploader.vue';
import imageCompression from 'browser-image-compression';
import { useField, useForm } from 'vee-validate';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { useRoute, useRouter } from 'vue-router';
import * as yup from 'yup';

const router = useRouter();
const route = useRoute();
const articleStore = useArticleStore();
const categoryStore = useCategoryStore();
const editorComponent = ref<typeof EditorComponent>();
const isArticle = ref(true);
const loading = ref(false);
const galleryImagesAsFile = ref<File[]>([]);
const attachedFiles = ref<File[] | null>(null);

const parsedEditorContent = computed(() => {
  if (!articleStore.article?.content) return { blocks: [] };

  if (typeof articleStore.article.content === 'string') {
    try {
      return JSON.parse(articleStore.article.content);
    } catch (e) {
      console.error('Error parsing content:', e);
      return articleStore.article.content;
    }
  }

  return articleStore.article.content;
});

const schema = yup.object({
  title: yup
    .string()
    .min(2, 'Заглавието на страницата трябва да е поне 2 символа')
    .required('Името на страницата е задължително'),
  categoryId: yup.number().required('Изборът на категория е задължителен'),
  content: yup.object().required('Съдържанието на страницата е задължително'),
  featuredImageAsFile: yup.mixed().nullable(),
  ogImageAsFile: yup.mixed().nullable(),
});

const { handleSubmit, errors } = useForm({
  validationSchema: schema,
  initialValues: articleStore.form,
});

const { value: title, errorMessage: titleError } = useField('title');
const { value: categories, errorMessage: categoriesError } = useField('categoryId');
const { value: featuredImageAsFile, errorMessage: featuredImageError } = useField<File | null>(
  'featuredImageAsFile',
);
const { value: ogImageAsFile, errorMessage: ogImageError } = useField('ogImageAsFile');
const { value: content, errorMessage: contentError } = useField('content');

function handleImageError(value: string | undefined) {
  if (value) {
    console.error('Failed to load image:', value);
  } else {
    console.error('Failed to load image: undefined');
  }
}

onMounted(async () => {
  const articleId = route.params.id as string;
  if (articleId) {
    try {
      await Promise.all([categoryStore.getAllCategories(), articleStore.getArticle(articleId)]);
      if (articleStore.article) {
        let parsedContent = articleStore.article.content;

        if (articleStore.article.featuredImage) {
          const response = await fetch(getImageUrl(articleStore.article.featuredImage));
          const blob = await response.blob();

          const file = new File([blob], articleStore.article.featuredImage, {
            type: blob.type,
          });
          featuredImageAsFile.value = file;
          articleStore.form.featuredImageAsFile = file;
        }

        if (articleStore.article.ogImage) {
          const response = await fetch(getImageUrl(articleStore.article.ogImage));
          const blob = await response.blob();
          const file = new File([blob], articleStore.article.ogImage, {
            type: blob.type,
          });
          articleStore.form.ogImageAsFile = file;
          articleStore.form.ogImagePreview = URL.createObjectURL(file);
        }

        if (articleStore.article.files) {
          const response = await Promise.all(
            articleStore.article.files.map(async (file: string) => {
              const response = await fetch(getImageUrl(file));
              const blob = await response.blob();

              return new File([blob], file, {
                type: blob.type,
              });
            }),
          );

          galleryImagesAsFile.value = response;
          articleStore.form.galleryImagesAsFile = response;
        }

        if (articleStore.article.attachedFiles) {
          const files = articleStore.article.attachedFiles;
          const response = await Promise.all(
            files.map(async (file: CustomFile) => {
              const response = await fetch(getImageUrl(file.name));
              const blob = await response.blob();
              const fileBlob = new File([blob], file.name, {
                type: blob.type,
              }) as CustomFile;
              fileBlob.fileTitle = file.fileTitle;
              return fileBlob;
            }),
          );
          attachedFiles.value = response;
        }

        if (typeof parsedContent === 'string') {
          try {
            parsedContent = JSON.parse(parsedContent);
          } catch (e) {
            parsedContent;
          }
        }

        title.value = articleStore.article.title;
        categories.value = articleStore.article.categoryId;
        content.value = parsedContent;
        articleStore.form = {
          ...articleStore.form,
          title: articleStore.article.title,
          categoryId: articleStore.article.categoryId,
          parentCategoryId: articleStore.article.parentCategoryId,
          url: articleStore.article.url,
          galleryImages: articleStore.article.files,
          content: parsedContent,
          metaTitle: articleStore.article.metaTitle,
          metaDescription: articleStore.article.metaDescription,
          metaRobots: articleStore.article.metaRobots,
          ogTitle: articleStore.article.ogTitle,
          ogDescription: articleStore.article.ogDescription,
          datePublished: formatDateForInput(articleStore.article.datePublished),
          endDate: formatDateForInput(articleStore.article.endDate),
        };
      }
    } catch (error) {
      console.log('Error fetching article:', error);
      toast.error('Грешка при зареждане на страницата');
      // router.push({ name: 'articles' });
    }
  }
});

watch(
  [
    categories,
    () => articleStore.form.categoryId,
    () => articleStore.form.parentCategoryId,
    () => articleStore.form.ogImagePreview,
  ],
  newValue => articleStore.watchCategoriesChange(newValue),
);

watch(
  () => title.value,
  newValue => {
    articleStore.urlChange(
      newValue,
      articleStore.article?.datePublished,
      articleStore.article?.isDraft,
    );
  },
);

watch(
  () => articleStore.form.ogImageAsFile,
  newFile => {
    if (newFile) {
      articleStore.form.ogImagePreview = URL.createObjectURL(newFile);
    } else {
      if (articleStore.form.ogImagePreview) {
        URL.revokeObjectURL(articleStore.form.ogImagePreview);
      }
      articleStore.form.ogImagePreview = null;
    }
  },
);

watch(
  () => articleStore.form.featuredImageAsFile,
  newFile => {
    if (newFile) {
      articleStore.form.featuredImagePreview = URL.createObjectURL(newFile);
    } else {
      if (articleStore.form.featuredImagePreview) {
        URL.revokeObjectURL(articleStore.form.featuredImagePreview);
      }
      articleStore.form.featuredImagePreview = null;
    }
  },
);

async function compressImage(file: File) {
  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1280,
    useWebWorker: true,
    initialQuality: 0.8,
    fileType: 'image/webp',
  };
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    toast.error('Неуспешно компресиране на изображението');
    return file;
  }
}

async function handleImageChange(
  type: 'featuredImageAsFile' | 'ogImageAsFile' | 'galleryImagesAsFile',
  files: File | File[] | null,
) {
  if (files) {
    if (Array.isArray(files)) {
      const compressedFiles = await Promise.all(files.map(file => compressImage(file)));
      articleStore.onImageChange(type, compressedFiles);
    } else {
      const compressedFile = await compressImage(files);
      articleStore.onImageChange(type, compressedFile);
    }
  } else {
    articleStore.onImageChange(type, null);
  }
}

function onFeaturedImageChange(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0] || null;
  if (!file) {
    articleStore.onImageChange('featuredImageAsFile', null);
  } else {
    handleImageChange('featuredImageAsFile', file);
  }
}

function onOgImageChange(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0] || null;
  handleImageChange('ogImageAsFile', file);
  if (file) {
    articleStore.form.ogImagePreview = URL.createObjectURL(file);
  } else {
    articleStore.form.ogImagePreview = null;
  }
}

const handleGalleryChange = async (files: File[]) => {
  try {
    if (files && files.length > 0) {
      const compressedFiles = await Promise.all(
        files.map(async file => {
          const existingFile = galleryImagesAsFile.value?.find(
            f => f.name === file.name && f.size === file.size,
          );
          if (existingFile) {
            return existingFile;
          }
          return await compressImage(file);
        }),
      );

      articleStore.onImageChange('galleryImagesAsFile', compressedFiles);

      if (
        !galleryImagesAsFile.value ||
        JSON.stringify(galleryImagesAsFile.value.map(f => f.name)) !==
          JSON.stringify(compressedFiles.map(f => f.name))
      ) {
        galleryImagesAsFile.value = compressedFiles;
      }
    } else {
      articleStore.onImageChange('galleryImagesAsFile', null);
      galleryImagesAsFile.value = [];
    }
  } catch (error) {
    console.error('Error processing gallery images:', error);
    toast.error('Грешка при обработка на изображенията');
  }
};

function onClearFeaturedImage() {
  featuredImageAsFile.value = null;
  articleStore.article.featuredImage = null;
  articleStore.onImageChange('featuredImageAsFile', null);
}

function onClearOgImage() {
  articleStore.form.ogImagePreview = null;
  articleStore.article.ogImage = null;
  articleStore.onImageChange('ogImageAsFile', null);
}

const handleFileChange = (files: File[]) => {
  attachedFiles.value = files;
};

function saveDraft() {
  articleStore.form.isDraft = true;
  submitForm();
}

async function submitForm() {
  if (isArticle.value) {
    await editorComponent.value?.saveData();

    const isEditorEmpty = await editorComponent.value?.isEmpty();
    if (isEditorEmpty) {
      toast.error('Не може да се запази празно съдържание');
      return;
    }
  }

  const onSubmit = handleSubmit(async validatedValues => {
    loading.value = true;

    try {
      articleStore.form.title = validatedValues.title;
      articleStore.form.categoryId = validatedValues.categoryId;
      articleStore.form.attachedFiles = attachedFiles.value || [];
      if (isArticle.value) {
        articleStore.form.content = validatedValues.content;
      }
      if (articleStore.form.isDeleted) {
        articleStore.form.isDeleted = false;
      }
      const articleId = route.params.id as string;
      await articleStore.updateArticle(articleId);

      toast.success('Страницата е успешно обновена!');

      setTimeout(() => {
        router.push({ name: 'articles' });
      }, 2000);
    } catch (error: any) {
      console.error('Failed to update article:', error);
      toast.error(error.message || 'Грешка при обновяване на страницата');
    } finally {
      loading.value = false;
    }
  });

  await onSubmit();
}

function cancelEdit() {
  if (
    confirm(
      'Сигурни ли сте, че искате да прекратите редактирането? Всички незапазени промени ще бъдат изгубени.',
    )
  ) {
    router.push({ name: 'articles' });
  }
}

onUnmounted(() => {
  if (articleStore.form.featuredImagePreview) {
    URL.revokeObjectURL(articleStore.form.featuredImagePreview);
  }
  if (articleStore.form.ogImagePreview) {
    URL.revokeObjectURL(articleStore.form.ogImagePreview);
  }
});
</script>

<style scoped>
.editor {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 300px;
}

.ga-3 {
  gap: 12px;
}

.ga-4 {
  gap: 16px;
}

.ga-5 {
  gap: 20px;
}

.ga-10 {
  gap: 40px;
}

.image-container {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

/* Add hover effect for images */
.image-container:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.file-input-wrapper {
  width: 100%;
}

.selected-files {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
