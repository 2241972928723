<template>
  <RouterView></RouterView>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';

import { onMounted } from 'vue';
import { useUserStore } from '@/stores/userStore';
const userStore = useUserStore();
onMounted(() => {
  userStore.hydrate();
});
</script>
