<template>
  <div>
    <v-btn color="primary" @click="$refs.fileInput.click()">
      <Icon :icon="plus" /> Добави снимки
    </v-btn>
    <input
      ref="fileInput"
      type="file"
      multiple
      accept="image/*"
      @change="onFileChange"
      class="d-none"
    />
    <v-row class="mt-4">
      <TransitionGroup name="gallery" tag="div" class="d-flex flex-wrap w-100">
        <v-col
          v-for="(image, index) in previewImages"
          :key="image.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="gallery-item"
        >
          <v-card class="image-card">
            <v-btn icon @click="removeImage(index)" class="delete-btn">
              <Icon :icon="deleteIcon" style="color: red" />
            </v-btn>
            <div class="image-wrapper">
              <v-img
                :src="image.url"
                height="200"
                :width="'100%'"
                cover
                :class="{ loading: image.loading }"
                class="gallery-image"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <v-card-text class="px-2 py-1">
              <div class="text-caption text-truncate">
                {{ image.file?.name }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </TransitionGroup>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue';

import deleteIcon from '@iconify-icons/mdi/close-circle';
import plus from '@iconify-icons/mdi/plus';
import { Icon } from '@iconify/vue';

interface PreviewImage {
  id: string;
  file?: File;
  url: string;
  loading: boolean;
}

const props = defineProps<{
  modelValue: File[];
}>();

const emit = defineEmits<{
  'update:modelValue': [files: File[]];
  galleryChange: [files: File[]];
}>();

const fileInput = ref<HTMLInputElement | null>(null);
const previewImages = ref<PreviewImage[]>([]);
const previewUrlCache = new Map<string, string>();

const createPreviewForFile = async (file: File): Promise<PreviewImage> => {
  const cacheKey = `${file.name}-${file.size}`;

  if (previewUrlCache.has(cacheKey)) {
    return {
      id: `img_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
      file,
      url: previewUrlCache.get(cacheKey)!,
      loading: false,
    };
  }

  const url = URL.createObjectURL(file);
  previewUrlCache.set(cacheKey, url);

  return {
    id: file.name,
    file,
    url,
    loading: false,
  };
};

const onFileChange = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;

  const files = Array.from(target.files);
  const currentFiles = props.modelValue || [];
  const updatedFiles = [...currentFiles, ...files];

  // Създаваме превюта за новите файлове
  for (const file of files) {
    const preview = await createPreviewForFile(file);
    preview.loading = true;
    previewImages.value.push(preview);
  }

  // Емитваме само galleryChange
  emit('galleryChange', updatedFiles);
  target.value = '';
};

const removeImage = (index: number) => {
  const newFiles = [...(props.modelValue || [])];
  newFiles.splice(index, 1);

  // Премахваме само конкретното изображение от превютата
  previewImages.value.splice(index, 1);

  // Емитваме само galleryChange, без update:modelValue
  emit('galleryChange', newFiles);
};

watch(
  () => props.modelValue,
  async newFiles => {
    if (!newFiles?.length) {
      previewImages.value = [];
      return;
    }

    // Филтрираме само файловете които още нямат превю
    const existingPreviews = new Set(
      previewImages.value.map(p => `${p.file?.name}-${p.file?.size}`),
    );

    const newFilesToPreview = newFiles.filter(
      file => !existingPreviews.has(`${file.name}-${file.size}`),
    );

    if (newFilesToPreview.length > 0) {
      const newPreviews = await Promise.all(
        newFilesToPreview.map(file => createPreviewForFile(file)),
      );

      // Добавяме само новите превюта
      previewImages.value = [...previewImages.value, ...newPreviews];
    }

    // Премахваме превютата на файлове които вече ги няма
    const currentFileKeys = new Set(newFiles.map(f => `${f.name}-${f.size}`));

    previewImages.value = previewImages.value.filter(preview =>
      currentFileKeys.has(`${preview.file?.name}-${preview.file?.size}`),
    );
  },
  { deep: true },
);

onUnmounted(() => {
  previewUrlCache.clear();
});

defineExpose({
  getFiles: () => previewImages.value.map(img => img.file),
});
</script>

<style scoped lang="scss">
.image-card {
  position: relative;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.image-wrapper {
  position: relative;
  flex: 1;
  min-height: 200px;
  overflow: hidden;
}

.gallery-image {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.delete-btn {
  &:hover {
    background-color: transparent !important;
    box-shadow: none;
  }
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  background-color: transparent;
  transition: background-color 0.3s ease;
  span svg {
    font-size: 25px;
    box-shadow: 0 0 15px 3px red;
    border-radius: 100%;
    border: 0;
  }
}

.delete-btn:hover {
  background-color: rgba(162, 162, 162, 1);
}

.loading {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

/* Добавяме анимации */
.gallery-item {
  transition: all 0.3s ease-in-out;
}

.gallery-enter-active,
.gallery-leave-active {
  transition: all 0.3s ease-in-out;
}

.gallery-enter-from,
.gallery-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.gallery-move {
  transition: transform 0.3s ease-in-out;
}

/* Подобряваме стила на картичката */
.v-card-text {
  background: rgba(255, 255, 255, 0.9);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
