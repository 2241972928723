import { makeElement } from './helper';

import { getRelativeCoordsOfTwoElems } from './dom';

export const showSetupMenu = (l, { config = {}, fillLink = true, removeCallback = () => { }, confirmCallback = () => { } } = {}) => {
  let menu = l.querySelector('.ce-link-setup-menu');
  const existingMenu = document.querySelector('.ce-link-setup-menu');

  if (existingMenu && existingMenu.dataset.linkId !== l.id) {
    existingMenu.remove();
  }

  const container = document.querySelector('.codex-editor');
  const containerWidth = container.offsetWidth;
  const lineHeight = parseFloat(getComputedStyle(l).lineHeight);
  const isLinkOnMoreThanOneLine = l.offsetHeight > lineHeight;
  const range = document.createRange();
  range.setStart(l, 0);
  range.setEnd(l, 1);

  const rects = range.getClientRects();
  const firstLinePosition = rects.length > 0 ? rects[0] : null;
  const linkPosition = firstLinePosition || l.getBoundingClientRect();

  let menuInLeft = !isLinkOnMoreThanOneLine && ((linkPosition.left + 470) < containerWidth);
  const menuInRight = (linkPosition.right + 470) > containerWidth;
  const noMenuStyleManipulation = isLinkOnMoreThanOneLine && ((linkPosition.left + 470) < containerWidth);

  const editor = document.querySelector('.editor-holder');
  const { fromLeftBorder } = getRelativeCoordsOfTwoElems(editor, l);
  const { fromTopBorder } = getRelativeCoordsOfTwoElems(editor, l);
  const { fromRightBorder } = getRelativeCoordsOfTwoElems(editor, l);

  if (!menuInLeft && !menuInRight) {
    menuInLeft = true;
  }

  if (!menu) {
    menu = makeElement('div', 'ce-link-setup-menu');
    menu.dataset.linkId = l.id;
    menu.contentEditable = 'false';
    menu.focus();

    const text = makeElement('div');
    const textLabel = makeElement('label');
    textLabel.innerHTML = 'Text to display';
    const textInput = makeElement('input');
    textInput.type = 'text';
    textInput.value = l.textContent;
    textInput.placeholder = 'Anchor text';
    textInput.classList.add('ce-link-input');
    text.appendChild(textLabel);
    text.appendChild(textInput);

    const link = makeElement('div');
    const linkLabel = makeElement('label');
    linkLabel.innerHTML = 'Link';
    const linkInput = makeElement('input');
    linkInput.type = 'file';
    linkInput.placeholder = 'Write or paste link';
    linkInput.classList.add('ce-link-input');

    let uploaded = false;

    const uploadButton = makeElement('button', 'bg-warning', { disabled: true });
    uploadButton.innerHTML = `Качи на сървъра`;
    uploadButton.addEventListener('click', () => {

      config.uploader.uploadFile(linkInput.files[0])
        // .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            uploaded = true;
          }
          const button = menu.querySelector('.btn-text');
          if (uploaded) {
            button.classList.add('show-btn-text');
            confirm.classList.remove('disabled');
          } else {
            button.classList.remove('show-btn-text');
            confirm.classList.add('disabled');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });

    linkInput.addEventListener('change', (e) => {
      if (e.target.value) {
        uploadButton.disabled = false;
        uploadButton.classList.remove('disabled');
      } else {
        uploadButton.disabled = true;
        uploadButton.classList.add('disabled');
      }
    });

    link.appendChild(linkLabel);
    link.appendChild(linkInput);
    link.appendChild(uploadButton);

    const buttons = makeElement('div', 'link-buttons');
    buttons.style.display = 'flex';

    const remove = makeElement('button', 'btn-text');
    remove.style.display = 'flex';
    remove.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path d="M17.1284 5.04102L3.37842 5.04102" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.37891 8.79102V13.791" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.1289 8.79102V13.791" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.8789 5.04102V16.916C15.8789 17.0818 15.8131 17.2407 15.6958 17.358C15.5786 17.4752 15.4197 17.541 15.2539 17.541H5.25391C5.08815 17.541 4.92917 17.4752 4.81196 17.358C4.69475 17.2407 4.62891 17.0818 4.62891 16.916V5.04102" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.3789 5.04102V3.79102C13.3789 3.4595 13.2472 3.14155 13.0128 2.90713C12.7784 2.67271 12.4604 2.54102 12.1289 2.54102H8.37891C8.04739 2.54102 7.72944 2.67271 7.49502 2.90713C7.2606 3.14155 7.12891 3.4595 7.12891 3.79102V5.04102" stroke="#748190" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>Remove link`;

    remove.addEventListener('click', () => {
      menu.remove();
      removeCallback();
    });

    const confirmClasses = ['bg-primary'];

    if (!linkInput.value) {
      confirmClasses.push('disabled');
    }
    const confirm = makeElement('button', confirmClasses);
    confirm.innerHTML = 'Confirm';
    confirm.addEventListener('click', () => {
      console.log('linkInput -> ', linkInput)
      menu.remove();
      l.innerHTML = textInput.value;
      l.href = linkInput.value;
      confirmCallback(linkInput.value);
    });

    buttons.appendChild(remove);
    buttons.appendChild(confirm);

    menu.appendChild(text);
    menu.appendChild(link);
    menu.appendChild(buttons);

    if (!noMenuStyleManipulation) {
      if (menuInLeft) {
        menu.style.left = '0px';
      } else {
        menu.style.right = '0px';
      }

      if (isLinkOnMoreThanOneLine) {
        menu.style.width = `${linkPosition.left >= 470 ? '470px' : `${linkPosition.left}px`}`;
        menu.style.right = `${fromRightBorder}px`;
      }
    }

    if (noMenuStyleManipulation) {
      if (menuInLeft) {
        menu.style.left = `${fromLeftBorder}px`;
        if (containerWidth - linkPosition.left >= 470) {
          menu.style.width = '470px';
        } else {
          menu.style.width = `${(containerWidth - linkPosition.left) - 20}px`;
        }
      } else {
        menu.style.right = `${fromLeftBorder}px`;
        menu.style.width = '470px';
      }

      if (isLinkOnMoreThanOneLine) {
        menu.style.left = `${fromLeftBorder}px`;
      }
    }

    editor.appendChild(menu);

    if (linkInput.value) {
      button.classList.add('show-btn-text');
    }

    menu.style.top = `${fromTopBorder + 28}px`;

    if (!isLinkOnMoreThanOneLine) {
      if (menuInLeft) {
        menu.style.left = `${fromLeftBorder}px`;
        if (containerWidth - linkPosition.left >= 470) {
          menu.style.width = '470px';
        } else {
          menu.style.width = `${(containerWidth - linkPosition.left) - 20}px`;
        }
      } else {
        menu.style.right = `${fromRightBorder}px`;
        if (linkPosition.left >= 470) {
          menu.style.width = '470px';
        } else {
          menu.style.width = `${linkPosition.left}px`;
        }
      }
    }

    textInput.focus();
  } else {
    menu.style.display = 'block';
  }
};


export const unwrap = (api, termWrapper, force = false) => {
  const wrapperContent = termWrapper.textContent;
  /**
   * Expand selection to all term-tag
   */
  api.selection.expandToTag(termWrapper);

  const sel = window.getSelection();
  const range = sel.getRangeAt(0);

  if (!force && wrapperContent === range.cloneContents().textContent) {
    showSetupMenu(termWrapper);
    return;
  }

  const unwrappedContent = range.extractContents();

  /**
   * Remove empty term-tag
   */
  termWrapper.parentNode.removeChild(termWrapper);

  /**
   * Insert extracted content
   */
  range.insertNode(unwrappedContent);

  /**
   * Restore selection
   */
  sel.removeAllRanges();
  sel.addRange(range);
};

export const createEditLinkWindow = (element) => {
  if (element.querySelector('.ce-edit-link-window') || element.querySelector('.ce-link-setup-menu')) {
    return;
  }

  if (!element.classList.contains('ce-inline-link') && !element.classList.contains('ce-link')) {
    return;
  }

  let linkPosition = element.getBoundingClientRect();
  const containerWidth = document.querySelector('.codex-editor').offsetWidth;

  const editWindow = makeElement('div', 'ce-edit-link-window');
  editWindow.contentEditable = 'false';
  const link = makeElement('a', 'ce-target-link');
  link.href = element.href;
  link.innerHTML = element.href;
  link.target = '_blank';

  const copy = makeElement('div', 'ce-copy-link');
  copy.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <g clip-path="url(#clip0_9785_486145)">
    <path d="M10.1357 0.5H1.63574C1.08346 0.5 0.635742 0.947715 0.635742 1.5V10C0.635742 10.5523 1.08346 11 1.63574 11H10.1357C10.688 11 11.1357 10.5523 11.1357 10V1.5C11.1357 0.947715 10.688 0.5 10.1357 0.5Z" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.6357 3.5V12.5C13.6357 12.7652 13.5304 13.0196 13.3428 13.2071C13.1553 13.3946 12.901 13.5 12.6357 13.5H3.63574" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_9785_486145">
    <rect width="14" height="14" fill="white" transform="translate(0.135742)"/>
    </clipPath>
    </defs>
    </svg>`;

  copy.addEventListener('click', () => {
    window.$nuxt.$copy(element.href);
  });

  const edit = makeElement('div', 'ce-edit-link');
  edit.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M6.13574 13.5014H3.13574C3.00313 13.5014 2.87596 13.4487 2.78219 13.3549C2.68842 13.2612 2.63574 13.134 2.63574 13.0014V10.2085C2.63574 10.1428 2.64867 10.0778 2.6738 10.0172C2.69893 9.95649 2.73576 9.90137 2.78219 9.85494L10.2822 2.35494C10.376 2.26117 10.5031 2.2085 10.6357 2.2085C10.7684 2.2085 10.8955 2.26117 10.9893 2.35494L13.7822 5.14784C13.876 5.2416 13.9286 5.36878 13.9286 5.50139C13.9286 5.634 13.876 5.76117 13.7822 5.85494L6.13574 13.5014Z" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.63574 4L12.1357 7.5" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`;

  // eslint-disable-next-line no-unused-vars
  edit.addEventListener('click', (e) => {
    setTimeout(() => {
      showSetupMenu(element, {
        removeCallback: () => {
          // eslint-disable-next-line no-param-reassign
          element.outerHTML = element.innerHTML;
        },
      });
      editWindow.remove();
    });
  });

  const remove = makeElement('div', 'ce-remove-link');
  remove.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M13.6353 3.5L2.63525 3.5" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.63574 6.5V10.5" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.63574 6.5V10.5" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.6357 3.5V13C12.6357 13.1326 12.5831 13.2598 12.4893 13.3536C12.3955 13.4473 12.2684 13.5 12.1357 13.5H4.13574C4.00313 13.5 3.87596 13.4473 3.78219 13.3536C3.68842 13.2598 3.63574 13.1326 3.63574 13V3.5" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.6357 3.5V2.5C10.6357 2.23478 10.5304 1.98043 10.3428 1.79289C10.1553 1.60536 9.90096 1.5 9.63574 1.5H6.63574C6.37053 1.5 6.11617 1.60536 5.92864 1.79289C5.7411 1.98043 5.63574 2.23478 5.63574 2.5V3.5" stroke="#000C1F" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`;

  remove.addEventListener('click', (e) => {
    e.preventDefault();
    editWindow.remove();

    // Create a text node with the same innerHTML as the link
    const textNode = document.createTextNode(element.innerHTML);

    // Replace the link with the text node
    // eslint-disable-next-line no-param-reassign
    element.parentNode.replaceChild(textNode, element);

    // Set the range on the text node
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(textNode, textNode.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  });

  editWindow.appendChild(link);
  editWindow.appendChild(copy);
  editWindow.appendChild(edit);
  editWindow.appendChild(remove);

  element.appendChild(editWindow);

  // Get the width of the editWindow
  const editWindowWidth = editWindow.offsetWidth;

  const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
  const isLinkOnMoreThanOneLine = element.offsetHeight > lineHeight;

  if (isLinkOnMoreThanOneLine) {
    // Get the position of the first line
    [linkPosition] = element.getClientRects();
  }

  let menuInLeft = !isLinkOnMoreThanOneLine && ((linkPosition.left + 320) < containerWidth);
  const menuInRight = (linkPosition.right + 320) > containerWidth;
  const noEditWindowStyleManipulation = isLinkOnMoreThanOneLine && ((linkPosition.left + 320) < containerWidth);

  if ((!menuInLeft && !menuInRight) || noEditWindowStyleManipulation) {
    menuInLeft = true;
  }
  if (menuInLeft) {
    if (containerWidth - linkPosition.left >= 430) {
      editWindow.style.right = `${linkPosition.left}px`;
      editWindow.style.width = '430px';
    } else {
      editWindow.style.width = `${(containerWidth - linkPosition.left) - 20}px`;
    }
  } else if (linkPosition.left >= 430) {
    editWindow.style.width = '430px';
  } else {
    editWindow.style.width = `${linkPosition.left}px`;
  }

  if (menuInLeft) {
    editWindow.style.left = '0px';
  } else if (isLinkOnMoreThanOneLine) {
    if (element.parentElement.classList.contains('tc-cell')) {
      editWindow.style.right = '0px';
    } else {
      // Recursive function to simulate the movement of the edit window to the left
      // eslint-disable-next-line no-inner-declarations
      function simulateMoveEditWindow(currentLeftPosition) {
        const newLeftPosition = currentLeftPosition - 30;

        // Check if the new position is outside the container
        if (newLeftPosition + editWindowWidth >= containerWidth) {
          simulateMoveEditWindow(newLeftPosition);
        } else {
          return newLeftPosition;
        }

        return 0;
      }

      editWindow.style.right = `${-simulateMoveEditWindow(editWindow.getBoundingClientRect().left)}px`;
    }
  } else {
    editWindow.style.right = '0px';
  }

  const container = document.querySelector('.codex-editor');

  const containerPosition = container.getBoundingClientRect();
  const linkStartsAtBeginning = linkPosition.left - containerPosition.left < 165;

  if (isLinkOnMoreThanOneLine) {
    if (menuInRight) {
      const screenWidth = window.innerWidth;
      editWindow.style.width = '430px';

      if (!linkStartsAtBeginning) {
        if (screenWidth > 1024 && screenWidth < 1439) {
          editWindow.style.right = '-50px';
        } else if (screenWidth > 1439) {
          editWindow.style.right = '0';
          editWindow.style.left = '-198px';
        }
      } else {
        editWindow.style.left = '0px';
      }
    }
  }
};

export const removeEditLinkWindow = () => {
  const editWindow = document.querySelector('.ce-edit-link-window');

  if (editWindow) {
    editWindow.remove();
  }
};

export const setLinkListeners = (link) => {
  let removeTimeout;

  link.addEventListener('mouseover', (e) => {
    clearTimeout(removeTimeout);
    const dropdown = document.querySelector('.ce-link-dropdown');

    if (dropdown?.dataset.linkId !== link.id) {
      createEditLinkWindow(e.target);
    }
  });

  link.addEventListener('mouseleave', () => {
    removeTimeout = setTimeout(() => {
      removeEditLinkWindow();
    }, 300);
  });

  link.addEventListener('click', (e) => {
    if (!e.target.closest('.ce-target-link')) {
      e.preventDefault();
    }

    if (e.target.closest('.ce-link-setup-menu')) {
      return;
    }

    const editMenu = link.querySelector('.ce-edit-link-window');

    if (editMenu) {
      editMenu.remove();
    }

    showSetupMenu(link, {
      removeCallback: () => {
        // eslint-disable-next-line no-param-reassign
        link.outerHTML = link.innerHTML;
      },
    });
  });
};
