/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/prefer-default-export
export const makeElement = (tagName, classNames = null, attributes = {}) => {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const escapeHtml = (unsafe) => unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;')
  .replaceAll("'", '&#039;');

export const parseUrl = (url) => {
  const [host, params = ''] = url.split('?');

  const query = params.split('&').reduce((p, c) => {
    const [k, v] = c.split('=');

    return { ...p, [k]: v };
  }, {});

  return {
    host,
    query,
  };
};

export const makeFragment = (htmlString) => {
  const tempDiv = document.createElement('div');

  tempDiv.innerHTML = htmlString.trim();

  const fragment = document.createDocumentFragment();

  fragment.append(...Array.from(tempDiv.childNodes));

  return fragment;
};

export const countWords = (s) => {
  s = s.replace(/(^\s*)|(\s*$)/gi, '');// exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi, ' ');// 2 or more space to 1
  s = s.replace(/\n /gm, '\n'); // exclude newline with a start spacing
  s = s.replace(/\n/gm, ' '); // exclude newline with a start spacing
  return s.split(' ').filter((str) => str !== '').length;
};

export const pasteInTemplate = (event) => {
  const isLinkSetupMenu = event.target.closest('.ce-link-setup-menu');
  if (isLinkSetupMenu) {
    return false;
  }

  event.preventDefault();
  event.stopImmediatePropagation();
  event.stopPropagation();
  const text = (event.clipboardData || window.clipboardData).getData('text');
  const selection = window.getSelection();
  if (!selection.rangeCount) return false;
  selection.deleteFromDocument();
  selection.getRangeAt(0).insertNode(document.createTextNode(text));
  selection.collapseToEnd();

  return true;
};
