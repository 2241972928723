<template>
  <v-container>
    <h1>Добавяне на страница</h1>
    <v-divider class="mt-4"></v-divider>

    <v-form @submit="submitForm" class="mt-8">
      <!-- Заглавие -->
      <v-text-field
        v-model="title"
        label="Име на страницата *"
        :error-messages="titleError"
        required
        hint="Добавете заглавие на страницата"
      ></v-text-field>

      <!-- Автор -->
      <v-text-field
        v-model="articleStore.form.authorId"
        readonly
        label="Автор"
        type="input"
      ></v-text-field>

      <!-- Категория -->
      <div class="d-flex justify-space-around ga-10">
        <v-autocomplete
          v-model="categories"
          :items="categoryStore.groupedCategoriesArray"
          item-title="bgname"
          item-value="id"
          label="Категория *"
          :error-messages="categoriesError"
          required
          clearable
        ></v-autocomplete>

        <v-select
          v-model="articleStore.form.parentCategoryId"
          :items="articleStore.parentCategories"
          item-title="bgname"
          item-value="id"
          label="Главна категория"
          clearable
        ></v-select>
      </div>

      <!-- URL -->
      <v-text-field v-model="articleStore.form.url" readonly label="URL"></v-text-field>

      <!-- Качване на страница -->
      <v-checkbox v-model="isArticle" label="Качване на страница"></v-checkbox>
      <editor-component
        v-if="isArticle"
        ref="editorComponent"
        v-model:content="content"
        :error-messages="contentError"
      ></editor-component>

      <!-- Представено изображение -->
      <v-file-input
        v-model="featuredImageAsFile"
        label="Представено изображение"
        :error-messages="featuredImageError"
        required
        accept="image/*"
        @change="onFeaturedImageChange"
      ></v-file-input>

      <v-img
        v-if="articleStore.form.featuredImagePreview"
        :src="articleStore.form.featuredImagePreview"
        class="mt-4 mb-4 w-25"
      ></v-img>

      <!-- Галерия със снимки -->
      <div class="mb-9">
        <h3 class="mb-5">Добавете галерия със снимки</h3>
        <ImageUploader v-model="galleryImagesAsFile" @galleryChange="handleGalleryChange" />
      </div>

      <!-- Файлове -->
      <div class="mb-9">
        <h3 class="mb-5">Добавете файлове</h3>
        <FileUploader v-model="attachedFiles" @fileChange="handleFileChange" />
      </div>

      <!-- Дата на публикуване -->
      <div class="d-flex ga-3">
        <v-text-field
          v-model="articleStore.form.datePublished"
          label="Дата на публикуване"
          type="date"
          required
        ></v-text-field>
        <v-text-field
          v-model="articleStore.form.endDate"
          label="Насрочена дата на публикуване"
          type="date"
          required
        ></v-text-field>
      </div>

      <!-- Meta и OG Данни -->
      <div class="mb-4">
        <v-btn type="button" @click.prevent="toggleMetaOG" color="secondary">
          {{ isMetaOGVisible ? 'Скрий Meta и OG данните' : 'Покажи Meta и OG данните' }}
        </v-btn>
        <!-- <button @click.prevent="toggleMetaOG" class="btn btn-secondary">
          {{ isMetaOGVisible ? 'Скрий Meta и OG данните' : 'Покажи Meta и OG данните' }}
        </button> -->
        <div v-if="isMetaOGVisible" class="mt-4">
          <v-text-field v-model="articleStore.form.metaTitle" label="Meta Заглавие"></v-text-field>
          <v-textarea
            v-model="articleStore.form.metaDescription"
            label="Meta Описание"
          ></v-textarea>

          <div class="d-flex ga-5">
            <v-checkbox
              v-model="articleStore.form.metaRobots.noIndex"
              label="No index"
            ></v-checkbox>
            <v-checkbox
              v-model="articleStore.form.metaRobots.noFollow"
              label="No follow"
            ></v-checkbox>
          </div>

          <v-text-field v-model="articleStore.form.ogTitle" label="Og: Заглавие"></v-text-field>

          <v-file-input
            v-model="articleStore.form.ogImageAsFile"
            accept="image/*"
            label="Og: Изображение"
            :error-messages="ogImageError"
            @change="onOgImageChange"
            clearable
          ></v-file-input>

          <v-img
            v-if="articleStore.form.ogImagePreview"
            :src="articleStore.form.ogImagePreview"
            max-width="400"
            max-height="200"
            class="mt-4 mb-4"
          ></v-img>

          <v-textarea v-model="articleStore.form.ogDescription" label="Og: Описание"></v-textarea>
        </div>
      </div>

      <hr class="mb-4" />
      <!-- Бутоните -->
      <div class="d-flex ga-5">
        <v-btn
          type="submit"
          color="primary"
          :loading="publishLoading"
          :disabled="publishLoading || draftLoading || cancelLoading || !isFormValid"
        >
          Публикувай
        </v-btn>
        <v-btn
          color="warning"
          @click="saveDraft"
          :loading="draftLoading"
          :disabled="publishLoading || draftLoading || cancelLoading || !isFormValid"
        >
          Запази като чернова
        </v-btn>
        <v-btn
          color="secondary"
          @click="previewPage"
          :loading="draftLoading"
          :disabled="publishLoading || draftLoading || cancelLoading || !isFormValid"
        >
          Прегледай страницата
        </v-btn>
        <v-btn
          color="error"
          @click="cancel"
          :loading="cancelLoading"
          :disabled="publishLoading || draftLoading || cancelLoading"
        >
          Отказ
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';

import EditorComponent from '@/components/EditorComponent.vue';
import FileUploader from '@/components/articles/dashboard/FileUploader.vue';
import ImageUploader from '@/components/articles/dashboard/ImageUploader.vue';
import { useArticleStore } from '@/stores/articlesStore';
import { useCategoryStore } from '@/stores/categoryStore';

import imageCompression from 'browser-image-compression';
import { useField, useForm } from 'vee-validate';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { useRouter } from 'vue-router';
import * as yup from 'yup';

const router = useRouter();

const articleStore = useArticleStore();
const categoryStore = useCategoryStore();
const editorComponent = ref<typeof EditorComponent>();
const isArticle = ref(true);
const attachedFiles = ref<File[] | null>(null);
const publishLoading = ref(false);
const draftLoading = ref(false);
const cancelLoading = ref(false);
const isMetaOGVisible = ref(false);
const toggleMetaOG = () => {
  isMetaOGVisible.value = !isMetaOGVisible.value;
};

onMounted(() => {
  articleStore.resetForm();
  categoryStore.getAllCategories().catch(error => {
    //TODO: Handle error
    console.error('Failed to fetch categories:', error);
  });
});

const schema = yup.object({
  title: yup
    .string()
    .min(2, 'Заглавието на страницата трябва да е поне 2 символа')
    .required('Името на страницата е задължително'),
  categoryId: yup.string().required('Изборът на категория е задължителен'),
  content: yup.object().required('Съдържанието на страницата е задължително'),
});

const { handleSubmit, errors, meta } = useForm({
  validationSchema: schema,
  initialValues: articleStore.form,
});

const { value: title, errorMessage: titleError } = useField('title');
const { value: categories, errorMessage: categoriesError } = useField('categoryId');
const { value: featuredImageAsFile, errorMessage: featuredImageError } = useField<File | null>(
  'featuredImageAsFile',
);
const { value: ogImage, errorMessage: ogImageError } = useField('ogImage');
const { value: content, errorMessage: contentError } = useField('content');
const { value: galleryImagesAsFile, errorMessage: galleryImagesError } = useField<File[] | []>(
  'galleryImagesAsFile',
);

watch(
  [
    categories,
    () => articleStore.form.categoryId,
    () => articleStore.form.parentCategoryId,
    () => articleStore.form.ogImagePreview,
  ],
  () => articleStore.watchCategoriesChange(categories.value),
);

watch(
  () => content.value,
  newContent => {
    if (typeof newContent === 'string' && newContent.length > 0) {
      // Ако съдържанието на едитора не е празно, вземете първите 150 символа за описание
      const contentExcerpt = (newContent as string).substring(0, 150);
      articleStore.form.metaDescription = contentExcerpt;
      articleStore.form.ogDescription = contentExcerpt;
    } else {
      // Ако съдържанието е празно, използвайте заглавието като описание
      const fallbackDescription = (title.value as string) || 'Няма описание';
      articleStore.form.metaDescription = fallbackDescription;
      articleStore.form.ogDescription = fallbackDescription;
    }
  },
);

watch(
  () => title.value,
  newValue => {
    articleStore.form.metaTitle = newValue as string;
    articleStore.form.ogTitle = newValue as string;

    articleStore.urlChange(
      newValue,
      articleStore.article?.datePublished,
      articleStore.article?.isDraft,
    );
  },
);

watch(
  () => featuredImageAsFile.value,
  newFeaturedImage => {
    if (newFeaturedImage) {
      articleStore.form.ogImageAsFile = newFeaturedImage;
    }
  },
);

async function compressImage(file: File) {
  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1280,
    useWebWorker: true,
    initialQuality: 0.8,
    fileType: 'image/webp',
  };
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    toast.error('Неуспешно компресиране на изображението');
    return file;
  }
}

async function handleImageChange(
  type: 'featuredImageAsFile' | 'ogImageAsFile' | 'galleryImagesAsFile',
  files: File | File[] | null,
) {
  if (files) {
    if (Array.isArray(files)) {
      const compressedFiles = await Promise.all(files.map(file => compressImage(file)));
      articleStore.onImageChange(type, compressedFiles);
    } else {
      const compressedFiles = await compressImage(files);
      articleStore.onImageChange(type, compressedFiles);
    }
  } else {
    articleStore.onImageChange(type, null);
  }
}

function onFeaturedImageChange(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0] || null;
  handleImageChange('featuredImageAsFile', file);
}

function onOgImageChange(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0] || null;
  handleImageChange('ogImageAsFile', file);
}

const handleGalleryChange = async (files: File[]) => {
  try {
    if (files && files.length > 0) {
      const compressedFiles = await Promise.all(
        files.map(async file => {
          const existingFile = galleryImagesAsFile.value?.find(
            f => f.name === file.name && f.size === file.size,
          );
          if (existingFile) {
            return existingFile;
          }
          return await compressImage(file);
        }),
      );

      articleStore.onImageChange('galleryImagesAsFile', compressedFiles);

      if (
        !galleryImagesAsFile.value ||
        JSON.stringify(galleryImagesAsFile.value.map(f => f.name)) !==
          JSON.stringify(compressedFiles.map(f => f.name))
      ) {
        galleryImagesAsFile.value = compressedFiles;
      }
    } else {
      articleStore.onImageChange('galleryImagesAsFile', null);
      galleryImagesAsFile.value = [];
    }
  } catch (error) {
    console.error('Error processing gallery images:', error);
    toast.error('Грешка при обработка на изображенията');
  }
};

const handleFileChange = (files: File[]) => {
  attachedFiles.value = files;
};

function cancel() {
  articleStore.resetForm();
  router.push({ name: 'articles' });
}

async function previewPage() {
  await saveDraft(true);
  window.open(
    `${import.meta.env.VITE_COMMON_CLIENT_URL}/${articleStore.form.url}?preview=1`,
    '_blank',
  );
}

async function saveDraft(preview = false) {
  articleStore.form.isDraft = true;
  draftLoading.value = true;
  await submitForm(new Event('submit'), preview);
}
const isFormValid = computed(() => {
  const valid = meta.value.valid && !Object.keys(errors.value).length;
  return valid;
});
async function submitForm(event: Event, preview = false) {
  event.preventDefault();
  await editorComponent.value?.saveData();
  const isEditorEmpty = await editorComponent.value?.isEmpty();
  if (isArticle.value && isEditorEmpty) {
    toast.error('Не може да се запази празно съдържание');
    draftLoading.value = false;
    publishLoading.value = false;
    return;
  }

  handleSubmit(async validatedValues => {
    if (articleStore.form.isDraft) {
      draftLoading.value = true;
    } else {
      publishLoading.value = true;
    }
    articleStore.form.title = validatedValues.title;
    articleStore.form.categoryId = validatedValues.categoryId;
    articleStore.form.attachedFiles = attachedFiles.value || [];
    if (isArticle.value) {
      articleStore.form.content = validatedValues.content;
    }

    try {
      await articleStore.createArticle(preview);
      if (!preview) {
        toast.success('Страницата е успешно добавена!');
        title.value = '';
        categories.value = '';
        featuredImageAsFile.value = null;
        content.value = '';
        galleryImagesAsFile.value = [];
        attachedFiles.value = null;
        setTimeout(() => {
          router.push({ name: 'articles' });
        }, 3500);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      publishLoading.value = false;
      draftLoading.value = false;
    }
  })();
}
</script>

<style scoped>
.editor {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 300px;
}
</style>
