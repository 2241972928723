export function formatDateForInput(date: string | number | Date): string {
  if (!date) return '';

  let parsedDate: Date;

  if (typeof date === 'string') {
    if (date.includes('г.')) {
      const [datePart] = date.split(' г.');
      const [dateParts] = datePart.split(',');
      const [day, month, year] = dateParts.split('.');
      parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      parsedDate = new Date(date);
    }
  } else {
    parsedDate = new Date(date);
  }

  if (isNaN(parsedDate.getTime())) {
    return '';
  }

  return parsedDate.toISOString().split('T')[0];
}

export function formatDateForDisplay(date: string | number | Date): string {
  if (!date) return '';

  let parsedDate: Date;

  if (typeof date === 'string') {
    // Handle date strings in 'dd.mm.yyyy' or 'dd.mm.yyyy г.' format
    const dateString = date.replace(' г.', '').trim();
    const [day, month, year] = dateString.split('.');
    if (day && month && year) {
      parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      parsedDate = new Date(date);
    }
  } else {
    parsedDate = new Date(date);
  }

  if (isNaN(parsedDate.getTime())) {
    return '';
  }

  const dayStr = parsedDate.getDate().toString().padStart(2, '0');
  const monthStr = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
  const yearStr = parsedDate.getFullYear();

  return `${dayStr}-${monthStr}-${yearStr}`;
}